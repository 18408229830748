<template>
	<div class="main">
		<!-- 我的购买 -->
		<div class="nav-lf">
			<MusicType :width="90" :type="type" :style="{ paddingLeft: num + 'px' }" :tabsList="tabsList"
				:dropdownList="dropdownList" @select="selectchange"></MusicType>
		</div>
		<div class="main-box">
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
import MusicType from "@/components/content/MusicType.vue";


export default {
	props: {},
	computed: {},
	components: {
		MusicType
	},
	watch: {
		"$store.state.purchaseType": {
		  handler(newValue, oldValue) {
		    console.log("purchaseType", newValue);
			if(newValue == 1){
				this.dropdownList = [
					{
					  name: this.$t('havepaid')
					},
					{
					  name: this.$t('nonpayment')
					},
					{
					  name: this.$t('haveexpired')
					}
				]
			}else if(newValue == 2){
				this.dropdownList = [
					{
					  name: this.$t('all')
					},
					{
					  name: this.$t('havepaid')
					},
					{
					  name: this.$t('nonpayment')
					}
				]
			}
			
			// this.PurchasedList()
		  },
		},
	},
	data() {
		return {
			num:'',
      type:'',
      dropdownList:[
		  // {
		  //   name: this.$t('all')
		  // },
		  // {
		  //   name: this.$t('havepaid')
		  // },
		  // {
		  //   name: this.$t('nonpayment')
		  // }
	  ],
			// tabs数据
			tabsList: [{
				name: this.$t('havebought'),
				url: '/personalCenter/MyPurchase/Purchased'
			},
			{
				name: this.$t('reserved'),
				url: '/personalCenter/MyPurchase/Locked'
			},
			],
		}
	},
	mounted() {
		console.log(this.$store.state.purchaseType,'ksksksksskk')
		if(this.$store.state.purchaseType == 1){
			this.dropdownList = [
				{
				  name: this.$t('havepaid')
				},
				{
				  name: this.$t('nonpayment')
				},
				{
				  name: this.$t('haveexpired')
				}
			]
		}else if(this.$store.state.purchaseType == 2){
			this.dropdownList = [
				{
				  name: this.$t('all')
				},
				{
				  name: this.$t('havepaid')
				},
				{
				  name: this.$t('nonpayment')
				}
			]
		}
		
	},
	methods: {
		selectchange(data){
			console.log(data,'selectchange')
			this.$store.state.myPurchaseStatus = data
			console.log(this.$store.state.myPurchaseStatus,'this.$store.state.myPurchaseStatus')
			// this.$store.commit('changePurchaseStatus', data)
			// console.log(this.$store.state.myPurchaseStatus,'this.$store.state.myPurchaseStatus')
			// this.$router.go(0)
		}
	}
}
</script>

<style lang="less" scoped>
.main {
	// color: palevioletred;
	::v-deep .nav .el-menu-item.is-active {
			border-bottom: 2px solid @lineColor;
		}

	.nav-lf {
		margin-left: 28px;
	}
	.main-box {
		margin-left: 30px;
	}
}
</style>